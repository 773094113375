<template>
  <v-menu
    v-model="datePickerMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px">
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="dateText"
        class="date-picker-input"
        style="padding-bottom: 1px"
        :label="label"
        append-icon="mdi-calendar"
        color="secondary"
        readonly
        v-bind="attrs"
        hide-details
        dense
        outlined
        :rules="rules"
        :disabled="disabled"
        v-on="on" />
    </template>
    <v-date-picker
      v-model="date"
      color="secondary"
      class="elevation-3"
      :type="type"
      :disabled="disabled">
      <v-spacer />
      <v-btn
        text
        small
        @click="datePickerClose()">
        {{ $t('fields.close') }}
      </v-btn>
      <v-btn
        text
        small
        @click="datePickerSubmit()">
        {{ $t('fields.submit') }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'date'
    },
    rules: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      datePickerMenu: false,
      date: '2019-09-10',
      dateText: '10/09/2019'
    }
  },
  watch: {
    value () {
      this.initDefaultDate()
    }
  },
  created () {
    this.initDefaultDate()
  },
  methods: {
    initDefaultDate () {
      if (!this.value) {
        this.date = this.$dayjs().format(this.type === 'month' ? 'YYYY-MM' : 'YYYY-MM-DD')
        this.dateText = ''
        return
      }

      if (this.type === 'month') {
        this.date = this.$dayjs(this.value).format('YYYY-MM')
        this.dateText = this.$dayjs(this.date).format('MM/YYYY')
      } else {
        this.date = this.$dayjs(this.value).format('YYYY-MM-DD')
        this.dateText = this.$dayjs(this.date).format('D/MM/YYYY')
      }
    },
    datePickerSubmit () {
      if (this.type === 'month') {
        this.dateText = this.$dayjs(this.date).format('MM/YYYY')
        this.$emit('input', this.$dayjs(this.date).format('YYYY-MM'))
      } else {
        this.dateText = this.$dayjs(this.date).format('D/MM/YYYY')
        this.$emit('input', this.$dayjs(this.date).format('YYYY-MM-DD'))
      }

      this.datePickerMenu = false
    },
    datePickerClose () {
      this.datePickerMenu = false
    }
  }
}
</script>
