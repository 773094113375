<template>
  <v-dialog
    v-model="dialog"
    max-width="70%">
    <v-card>
      <v-card-text>
        <v-container fluid>
          <v-row
            justify="space-between"
            align="center">
            <v-col cols="auto">
              <h2>Vendors</h2>
            </v-col>
            <v-col cols="auto">
              <div class="d-flex flex-row justify-end align-base-line">
                <search-box
                  v-model="filters.search"
                  :disabled="loading"
                  @on-search="getVendors(true)" />
              </div>
            </v-col>
          </v-row>
          <v-data-table
            class="mt-5"
            :headers="headers"
            :items="vendors"
            :items-per-page="filters.limit"
            :loading="loading"
            hide-default-footer>
            <template #[`item.searchName`]="{ item }">
              {{ item.searchName || '-' }}
            </template>
            <template #[`item.phoneNumber`]="{ item }">
              {{ item.phoneNumber || '-' }}
            </template>
            <template #[`item.email`]="{ item }">
              {{ item.email || '-' }}
            </template>
            <template #[`item.vendorType`]="{ item }">
              {{ mapType(item.vendorType.name) }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                color="secondary"
                outlined
                :loading="loading"
                @click="onSelect(item)">
                Select
              </v-btn>
            </template>
          </v-data-table>
          <v-pagination
            v-model="filters.page"
            :total-visible="7"
            :length="pageCount"
            :disabled="loading" />
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import VendorsProvider from '@/resources/VendorsProvider'
import SearchBox from '@/components/SearchBox.vue'

const VendorsService = new VendorsProvider()

export default {
  components: {
    SearchBox
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      vendors: [],
      pageCount: 0,
      filters: {
        search: '',
        page: 1,
        limit: 10
      },
      headers: [
        {
          text: 'รหัส',
          value: 'vendorNo'
        },
        {
          text: 'ชื่อ',
          value: 'name'
        },
        {
          text: 'ชื่อสำหรับค้นหา',
          value: 'searchName'
        },
        {
          text: 'เบอร์โทร',
          value: 'phoneNumber'
        },
        {
          text: 'อีเมล',
          value: 'email'
        },
        {
          text: 'ประเภท',
          value: 'vendorType'
        },
        {
          text: '',
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ],
      vendorTypes: [
        {
          text: 'นิติบุคคล',
          value: 'juristic'
        },
        {
          text: 'บุคคลธรรมดา',
          value: 'individual'
        }
      ]
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'filters.page': {
      handler () {
        this.getVendors()
      },
      deep: true
    }
  },
  mounted () {
    this.getVendors(true)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading'
    }),
    async getVendors (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.vendors = []
          this.filters.page = 1
        }

        const { data } = await VendorsService.getItems({
          search: this.filters.search,
          page: this.filters.page,
          limit: this.filters.limit
        })

        this.vendors = data.results
        this.pageCount = data.pages
      } catch (error) {
        console.error('getVendors', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    mapType (type) {
      const vendorType = this.vendorTypes.find((vt) => vt.value === type)

      return vendorType?.text || type
    },
    onSelect (item) {
      this.$emit('select', item)
    },
    closeForm () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
