<template>
  <v-container
    class="container-bg rounded elevation-3"
    fluid>
    <v-form
      ref="order-form"
      @submit.prevent="onSubmit()">
      <v-row>
        <v-col
          cols="12"
          class="d-flex flex-row justify-space-between align-center">
          <h2 v-if="isEdit">
            {{ `จัดการใบสั่งซื้อ - ${formData.orderNo}` }}
          </h2>
          <h2 v-else>
            จัดการใบสั่งซื้อ
          </h2>
          <div class="d-flex flex-row align-center">
            <v-btn
              v-if="formData.state === 'pending' && enableApprove"
              color="success"
              :disabled="loading || !isEdit"
              @click="onConfirm('approve')">
              อนุมัติ
            </v-btn>
            <template v-if="formData.state === 'approved' && enableApprove">
              <v-btn
                class="mr-2"
                color="warning"
                :disabled="disableCancel"
                @click="onConfirm('pending')">
                ย้อนสถานะ
              </v-btn>
              <v-btn
                color="error"
                :disabled="disableCancel"
                @click="onConfirm('cancel')">
                ยกเลิก
              </v-btn>
            </template>
            <v-menu
              v-if="formData.deliveryNoteIds.length"
              offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="info"
                  :disabled="loading || !isEdit || formData.state !== 'approved'"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on">
                  ดูใบนำเข้า
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in deliveryNoteRef"
                  :key="index"
                  @click="showDeliveryNote(item.id)">
                  <v-list-item-title>
                    <span
                      :class="{
                        'disabled-text': item.status === 'canceled'
                      }">
                      {{ item.code }}
                    </span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              v-if="type === 'FG'"
              class="mx-2"
              color="success"
              :disabled="loading || !isEdit || formData.state !== 'approved' || !isSync"
              @click="onConfirm('delivery')">
              สร้างใบนำเข้า
            </v-btn>
            <v-btn
              v-else
              class="mx-2"
              color="success"
              :disabled="loading || !isEdit || formData.state !== 'approved' || !isSync"
              @click="onConfirm('receive')">
              รับสินค้า
            </v-btn>
            <v-btn
              v-if="formData.state === 'pending' && enableApprove"
              :disabled="loading || !isEdit"
              small
              icon
              class="ml-2"
              target="_blank"
              :to="`/purchases/orders/${formData.id}/pdf`">
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col
          cols="12"
          class="header-line">
          <span>
            ข้อมูลผู้ขาย
          </span>
          <v-divider />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="vendorNumber"
            label="รหัสผู้ขาย"
            color="secondary"
            :rules="textBoxRules"
            dense
            outlined
            :disabled="!isPending"
            readonly
            hide-details />
        </v-col>
        <v-col
          cols="12"
          md="5">
          <v-text-field
            v-model="vendorName"
            label="ชื่อผู้ขาย"
            color="secondary"
            :rules="textBoxRules"
            dense
            outlined
            readonly
            hide-details
            :disabled="!isPending" />
        </v-col>
        <!-- <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="vendorInvoiceNumber"
            label="เลขที่ใบกำกับภาษีผู้ขาย"
            :rules="textBoxRules"
            color="secondary"
            dense
            outlined
            readonly
            hide-details
            :disabled="!isPending" />
        </v-col> -->
        <v-col
          cols="12"
          md="3">
          <v-btn
            color="secondary"
            :disabled="!isPending"
            @click="toggleVendor()">
            เลือกผู้ขาย
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          class="header-line">
          <span>
            ข้อมูลใบสั่งซื้อ
          </span>
          <v-divider />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <date-picker
            v-model="formData.documentDate"
            label="วันที่เอกสาร"
            :rules="textBoxRules"
            hide-details
            :disabled="loading || !isPending" />
        </v-col>
        <!-- <v-col
          cols="12"
          md="4">
          <date-picker
            v-model="formData.dueDate"
            label="วันที่ครบกำหนดจ่ายเงิน"
            :rules="textBoxRules"
            :disabled="loading || !isPending" />
        </v-col> -->
        <v-col
          cols="12"
          md="3">
          <v-select
            v-model="formData.brand"
            label="แบรนด์"
            color="secondary"
            :rules="textBoxRules"
            :items="productAttributes.brands"
            item-text="name"
            item-value="name"
            dense
            outlined
            hide-details
            :disabled="loading || isEdit" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-select
            v-model="formData.pricesIncludeTax"
            label="ราคารวม VAT"
            color="secondary"
            :items="vats"
            item-text="text"
            item-value="value"
            dense
            outlined
            hide-details
            :disabled="true" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-select
            v-model="formData.currencyCode"
            label="รหัสสกุลเงิน"
            placeholder="ถ้าเป็น THB ไม่ต้องส่งค่า"
            color="secondary"
            :items="currencies"
            item-text="text"
            item-value="value"
            dense
            outlined
            hide-details
            :disabled="loading || !isPending" />
        </v-col>
        <v-col
          cols="12"
          md="12">
          <v-select
            v-model="formData.locationCode"
            :items="filteredWarehouse"
            :menu-props="{ offsetY: true }"
            item-value="code"
            item-text="code"
            label="รหัสคลังสินค้าที่รับสินค้า"
            :rules="textBoxRules"
            color="secondary"
            outlined
            dense
            hide-details
            :disabled="loading || !isPending"
            @change="changeLocationCode" />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="shipAddress"
            label="ที่อยู่จัดส่ง"
            color="secondary"
            dense
            outlined
            :rules="[maxLengthRule(150)]"
            hide-details
            :disabled="loading || !isPending" />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="formData.remark"
            label="หมายเหตุ"
            color="secondary"
            dense
            outlined
            hide-details
            :rules="[maxLengthRule(250)]"
            :disabled="loading || !isPending" />
        </v-col>
        <!-- <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model.number="formData.discountAmount"
            label="ส่วนลดท้ายบิล"
            color="secondary"
            type="number"
            dense
            outlined
            hide-details
            :disabled="loading || !isPending" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.approver"
            label="ผู้อนุมัติ"
            color="secondary"
            dense
            outlined
            hide-details
            :disabled="loading || isEdit" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <date-picker
            v-model="formData.approvedDate"
            label="วันที่อนุมัติ"
            :disabled="loading || isEdit" />
        </v-col>
        <v-col
          cols="12"
          md="6">
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.receiver"
            label="ผู้รับสินค้า"
            color="secondary"
            dense
            outlined
            hide-details
            :disabled="loading || isEdit" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <date-picker
            v-model="formData.receivedDate"
            label="วันที่รับสินค้า"
            :disabled="loading || isEdit" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.confirmGoodsReceiptNo"
            label="เลขที่ใบยืนยันรับสินค้า"
            color="secondary"
            dense
            outlined
            hide-details
            :disabled="loading || isEdit" />
        </v-col>
        <v-col
          cols="12"
          md="3">
        </v-col> -->
        <v-col
          cols="12"
          class="header-line">
          <span>
            {{ `ข้อมูล${typeText}` }}
          </span>
          <v-divider />
        </v-col>
        <v-col
          v-for="(item, index) in formData.purchaseOrderLines"
          :key="index"
          cols="12">
          <v-row class="pl-6">
            <v-col
              cols="12"
              class="mt-2">
              <div class="d-flex flex-row justify-space-between align-center">
                <div>
                  <span v-if="type === 'RM'">
                    {{ item.sequence || 0 }} : {{ item.itemNo || `${typeText}ใหม่` }} | {{ item.description }}
                  </span>
                  <span v-else>
                    {{ item.sequence || 0 }} : {{ item.itemNo || `${typeText}ใหม่` }} | {{ item.description }} - {{ item.color }} : {{ item.size }}
                  </span>
                  <span
                    v-if="isEdit"
                    class="text-bold">
                    (รับไปแล้ว {{ item.receivedQty || 0 }} / {{ item.quantity }} )
                  </span>
                </div>
                <v-btn
                  icon
                  color="error"
                  :disabled="formData.purchaseOrderLines.length <= 1 || !isPending"
                  style="margin-left: auto;"
                  @click="removeItem(index)">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </div>
              <v-divider />
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col
              cols="12"
              md="3">
              <img
                v-if="item.imageURL"
                :src="item.imageURL"
                class="elevation-1 main-image"
                alt="product-image">
              <img
                v-else
                :src="noImgAvailable"
                class="elevation-1 main-image"
                alt="product-image">
            </v-col>
            <v-col
              cols="12"
              md="9">
              <v-row>
                <!-- <v-col
                  cols="12"
                  md="4">
                  <v-text-field
                    v-model="item.itemNo"
                    :label="`รหัส${typeText}`"
                    color="secondary"
                    :rules="textBoxRules"
                    dense
                    outlined
                    hide-details
                    :disabled="loading || !isPending"
                    readonly />
                </v-col>
                <v-col
                  cols="12"
                  md="4">
                  <v-select
                    v-model="item.lineType"
                    label="ประเภทรายการ"
                    color="secondary"
                    :rules="textBoxRules"
                    :items="lineTypes"
                    hide-details
                    dense
                    outlined
                    :disabled="loading || !isPending" />
                </v-col> -->
                <v-col
                  v-if="type === 'RM'"
                  cols="12">
                  <v-autocomplete
                    v-model="item.useForProducts"
                    :search-input.sync="item.search"
                    label="สินค้าที่ใช้วัตถุดิบนี้"
                    color="secondary"
                    :rules="textBoxRules"
                    :items="computeBOMs"
                    chips
                    deletable-chips
                    multiple
                    small-chips
                    hide-details
                    dense
                    outlined
                    hide-no-data
                    hide-selected
                    :loading="loading || item.searching"
                    :disabled="loading || !isPending"
                    @update:search-input="handleUseForProducts(index)" />
                </v-col>
                <v-col
                  cols="12"
                  md="3">
                  <v-text-field
                    v-model.number="item.quantity"
                    :rules="textBoxRules"
                    :label="`จำนวนที่สั่งซื้อ (${item.uom})`"
                    color="secondary"
                    type="number"
                    dense
                    outlined
                    :disabled="loading || !isPending" />
                </v-col>
                <v-col
                  cols="12"
                  md="3">
                  <v-text-field
                    v-model.number="item.directUnitCost"
                    label="ราคาต่อหน่วย (THB)"
                    :rules="numberBoxRules"
                    color="secondary"
                    type="number"
                    dense
                    outlined
                    :disabled="loading || !isPending" />
                </v-col>
                <v-col
                  cols="12"
                  md="3">
                  <v-text-field
                    v-model.number="item.discountPercent"
                    label="ส่วนลด (%)"
                    :rules="percentBoxRules"
                    color="secondary"
                    type="number"
                    dense
                    outlined
                    :disabled="loading || !isPending" />
                </v-col>
                <v-col
                  cols="12"
                  md="3">
                  <date-picker
                    v-model="item.expectedReceiptDate"
                    label="วันที่รับสินค้า"
                    :rules="textBoxRules"
                    :disabled="loading || formData.state === 'canceled'" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-center align-center mt-4 flex-wrap"
          style="gap: 8px;">
          <v-btn
            color="secondary"
            :disabled="!isPending"
            @click="toggleItem()">
            <v-icon
              size="16px"
              class="mr-1">
              mdi-tshirt-crew-outline
            </v-icon>
            {{ `เพิ่ม${typeText}` }}
          </v-btn>
          <v-btn
            color="secondary"
            :disabled="!isPending"
            @click="addChargeItem('freight')">
            <v-icon
              size="16px"
              class="mr-1">
              mdi-truck
            </v-icon>
            เพิ่มค่าขนส่ง
          </v-btn>
          <v-btn
            color="secondary"
            :disabled="!isPending"
            @click="addChargeItem('labour')">
            <v-icon
              size="16px"
              class="mr-1">
              mdi-scissors-cutting
            </v-icon>
            เพิ่มค่าแรง
          </v-btn>
          <v-btn
            color="success"
            :disabled="formData.purchaseOrderLines.length === 0 || formData.state === 'canceled'"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <vendor-list-modal
      v-model="vendorDialog"
      @select="selectVendor($event)"
      @close="toggleVendor()" />
    <item-list-modal
      v-model="itemDialog"
      :type="type"
      :selected-items="formData.purchaseOrderLines"
      :disabled="loading"
      @select="onSelectItem($event)"
      @remove="onRemoveItem($event)"
      @close="toggleItem()" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import getImageOrGradientCss from '@/assets/js/GetImageOrGradientCss'
import { getRole } from '@/assets/js/Authentication'
import NoImgAvailable from '@/assets/image/no_picture_available.png'
import PurchaseOrdersProvider from '@/resources/PurchaseOrdersProvider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import PurchaseItemsProvider from '@/resources/PurchaseItemsProvider'
import WarehouseProvider from '@/resources/WarehouseProvider'
import UnitOfMeasuresProvider from '@/resources/UnitOfMeasuresProvider'
import PrototypeProvider from '@/resources/PrototypeProvider'
import InventoryProvider from '@/resources/InventoryProvider'
import AssemblyBOMProvider from '@/resources/AssemblyBOMProvider'
import DatePicker from '../components/DatePicker.vue'
import VendorListModal from '../components/VendorListModal.vue'
import ItemListModal from '../components/ItemListModal.vue'

const InventoryService = new InventoryProvider()
const PurchaseItemsService = new PurchaseItemsProvider()
const PurchaseOrdersService = new PurchaseOrdersProvider()
const ProductAttributeService = new ProductAttributeProvider()
const WarehouseService = new WarehouseProvider()
const UnitOfMeasuresService = new UnitOfMeasuresProvider()
const PrototypeService = new PrototypeProvider()
const AssemblyBOMService = new AssemblyBOMProvider()

export default {
  components: { VendorListModal, DatePicker, ItemListModal },
  data () {
    return {
      loading: false,
      isEdit: false,
      warehouses: [],
      textBoxRules: [
        (v) => !!v || 'This field is require'
      ],
      numberBoxRules: [
        (v) => (v !== '' && !Number.isNaN(v) && v > 0) || 'This field has an invalid'
      ],
      percentBoxRules: [
        (v) => (v !== '' && !Number.isNaN(v) && v >= 0 && v <= 100) || 'This field has an invalid'
      ],
      UOMs: [],
      BOMs: [],
      bomSearch: '',
      productAttributes: {
        brands: [],
        categories: [],
        colors: [],
        size: [],
        tags: [],
        collections: [],
        groupCategories: []
      },
      currencies: [
        { value: '', text: 'THB' },
        { value: 'CNY-S', text: 'CNY' }
        // { value: 'EUR-S', text: 'EUR' },
        // { value: 'SGD-S', text: 'SGD' },
        // { value: 'USD-S', text: 'USD' }
      ],
      vats: [
        { value: true, text: 'รวม' },
        { value: false, text: 'ไม่รวม' }
      ],
      formData: {
        documentDate: '',
        dueDate: null,
        vendor: '',
        yourReference: '',
        deliveryNoteIds: [],
        currencyCode: '',
        pricesIncludeTax: false,
        discountAmount: 0,
        noOfLines: 1,
        locationCode: '',
        shipAddress: '',
        shipAddress2: '',
        approver: '',
        approvedDate: '',
        confirmGoodsReceiptNo: '',
        receiver: '',
        receivedDate: '',
        remark: '',
        type: 'FG',
        purchaseOrderLines: [],
        brand: '',
        state: 'pending',
        poStatus: 'Wait',
        poClosed: false,
        bcSync: {
          sync: false,
          bcLog: null
        }
      },
      memoFormData: null,
      deliveryNoteRef: [],
      selectedVendor: null,
      lineTypes: [
        'Item',
        'Resource',
        'Charge (Item)'
      ],
      vendorDialog: false,
      itemDialog: false,
      noImgAvailable: NoImgAvailable
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouses: 'Store/mapWarehouse'
    }),
    filteredWarehouse () {
      const POWarehouse = this.type === 'RM'
        ? [
          'GW-FACTORY',
          'MM-FACTORY'
        ] : [
          'GW-WHS',
          'GW-SAMPLE',
          'MM-WHS',
          'MM-SAMPLE'
        ]

      return this.mapWarehouses.filter((wh) => POWarehouse.includes(wh.code))
    },
    enableApprove () {
      const role = getRole()
      const setCountRoles = [
        'management',
        'developer',
        'accounting_manager',
        'merchandising_planner',
        'merchandiser',
        'support'
      ]
      return setCountRoles.some((r) => r === role) && !this.formData.poClosed
    },
    vendorNumber () {
      return this.selectedVendor?.vendorNo || ''
    },
    vendorName () {
      return this.selectedVendor?.name || ''
    },
    vendorInvoiceNumber () {
      return this.selectedVendor?.taxRegistrationNumber || ''
    },
    isPending () {
      return this.formData.state === 'pending'
    },
    type () {
      return this.$route.query.type || 'RM'
    },
    duplicateId () {
      return this.$route.query.duplicate || ''
    },
    typeText () {
      return this.type === 'FG' ? 'สินค้า' : 'วัตถุดิบ'
    },
    isSync () {
      return this.formData.bcSync?.sync && this.formData.bcSync?.bcLog
    },
    disableCancel () {
      return this.loading
        || !this.isEdit
        || this.deliveryNoteRef.filter((dn) => dn.status !== 'canceled').length > 0
        || this.formData.poStatus !== 'Open'
    },
    shipAddress: {
      get () {
        return [this.formData.shipAddress, this.formData.shipAddress2].join('')
      },
      set (val) {
        this.formData.shipAddress = val.slice(0, 100)
        this.formData.shipAddress2 = val.slice(100, 150)
      }
    },
    computeBOMs () {
      const items = this.formData.purchaseOrderLines.reduce((arr, v) => (v.useForProducts ? [...arr, ...v.useForProducts] : arr), [])

      return [...new Set([...items, ...this.BOMs])]
    }
  },
  async mounted () {
    await Promise.all([this.getAllAttributes(), this.getAllWarehouses()])

    if (this.$route.params?.id) {
      this.isEdit = true
      await this.getItem()
      await this.getDeliveryNoteRef()
    } else if (this.duplicateId) {
      await this.getItem(true)
    } else {
      this.formData.type = this.type
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading',
      setModal: 'Components/setModal'
    }),
    handleUseForProducts (index) {
      clearTimeout(this.formData.purchaseOrderLines[index].searchTimeout)

      this.formData.purchaseOrderLines[index].searchTimeout = setTimeout(() => {
        this.getBOMs(index)
      }, 300)
    },
    async getDeliveryNoteRef () {
      this.loading = true
      try {
        const { data } = await InventoryService.getDeliveryNoteCodes({ ids: this.formData.deliveryNoteIds })
        this.deliveryNoteRef = data.map((d) => ({ id: d.id, code: d.code, status: d.status }))
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      }
      this.loading = false
    },
    changeLocationCode (val) {
      const location = this.warehouses.find((wh) => wh.code === val)
      this.formData.shipAddress = `${location.address} ${location.address2}`
      this.formData.shipAddress2 = `${location.subDistrict} ${location.district} ${location.stateProvince} ${location.postcode}`
    },
    maxLengthRule (length) {
      return (v) => v.length <= length || `Field นี้ต้องยาวไม่เกิน ${length} ตัวอักษร`
    },
    toggleVendor () {
      this.vendorDialog = !this.vendorDialog
    },
    toggleItem () {
      this.itemDialog = !this.itemDialog
    },
    async addChargeItem (chargeItemType) {
      const item = await this.getFixItem(chargeItemType)
      if (!item) {
        this.setSnackbar({
          value: true,
          message: `Item has purchaseItemType = "${chargeItemType}" not found.`,
          type: 'error'
        })
        return
      }
      this.formData.purchaseOrderLines.push({
        sequence: (this.formData.purchaseOrderLines.length + 1) * 10000,
        locationCode: '',
        quantity: 1,
        discountAmount: 0,
        discountPercent: 0,
        ifStatus: '',
        useForProducts: [],
        expectedReceiptDate: '',
        lineType: 'Item',
        itemType: 'RM',
        itemNo: item.itemNo,
        imageURL: item.imageURL,
        description: item.description,
        description2: item.description2.slice(0, 50),
        uom: item.baseUOM || item.salesUOM || item.purchaseUOM || '',
        directUnitCost: item.unitPrice,
        groupCategory: '',
        size: '',
        color: '',
        categories: '',
        model: 'NONE',
        gwCollection: '',
        brand: '',
        search: '',
        searching: false,
        searchTimeout: null
      })
    },
    async getFixItem (purchaseItemType) {
      try {
        this.loading = true
        const { data } = await PurchaseItemsService.getItems({
          purchaseItemType,
          search: '',
          page: 1,
          limit: 1
        })
        return data?.results?.[0] || null
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
        return null
      } finally {
        this.loading = false
      }
    },
    removeItem (index) {
      this.formData.purchaseOrderLines.splice(index, 1)
    },
    getColor (arr, itemsDisabled) {
      return arr.map((color) => ({
        ...color,
        label: color.name,
        children: color.childs && color.childs.length > 0 ? this.getColor(color.childs, itemsDisabled) : undefined,
        gradientCss: getImageOrGradientCss(color)
      }))
    },
    async getAllWarehouses () {
      try {
        this.loading = true

        const { data } = await WarehouseService.getWarehouse()
        this.warehouses = data
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async getAllAttributes () {
      try {
        this.loading = true
        this.setLoading({ active: true })

        const { data } = await ProductAttributeService.getAllProductAttribute()

        this.productAttributes = {
          colors: Array.isArray(data.colors) ? this.getColor(data.colors) : [],
          brands: Array.isArray(data.brands) ? data.brands : [],
          size: Array.isArray(data.size) ? data.size : [],
          tags: Array.isArray(data.tags) ? data.tags : [],
          categories: Array.isArray(data.categories) ? data.categories : [],
          collections: Array.isArray(data.collections) ? [{ id: null, name: 'ไม่ระบุ' }, ...data.collections] : [],
          groupCategories: Array.isArray(data.groupCategories) ? data.groupCategories : []
        }

        const { data: uom } = await UnitOfMeasuresService.getItems({
          page: 1,
          limit: 9999
        })

        this.UOMs = uom.results.map((r) => ({
          text: r.name,
          value: r.code
        }))
      } catch (error) {
        console.error('getAllAttributes', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    async getItem (isDuplicate = false) {
      try {
        this.loading = true
        this.setLoading({ active: true })

        const id = isDuplicate ? this.duplicateId : this.$route.params.id
        const { data } = await PurchaseOrdersService.getItemById(id)

        this.selectedVendor = data.vendor
        this.formData = isDuplicate ? {
          ...data,
          id: null,
          state: 'pending',
          vendor: data.vendor.id,
          deliveryNoteIds: []
        } : {
          ...data,
          vendor: data.vendor.id,
          deliveryNoteIds: data.deliveryNoteId ? [
            ...data.deliveryNoteIds,
            data.deliveryNoteId
          ] : data.deliveryNoteIds
        }

        this.memoFormData = JSON.parse(JSON.stringify(this.formData))
      } catch (error) {
        console.error('getItem', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    async getBOMs (index) {
      try {
        this.formData.purchaseOrderLines[index].searching = true

        const { data } = await AssemblyBOMService.getItems({
          page: 1,
          limit: 20,
          search: this.bomSearch
        })

        this.BOMs = data.results.map((v) => v.parentItemNo)
      } catch (error) {
        console.error('getBOMs', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.formData.purchaseOrderLines[index].searching = false
      }
    },
    onConfirm (type) {
      if (type === 'approve') {
        this.setModal({
          value: true,
          title: 'Approve',
          message: 'Do you want to approve this purchase order',
          confirmText: 'Sure',
          confirmType: 'error',
          cancelType: '',
          cancelText: 'Cancel',
          onConfirm: () => this.onApprove()
        })
      } else if (type === 'receive') {
        this.setModal({
          value: true,
          title: 'Receive',
          message: 'Do you want to receive this purchase order',
          confirmText: 'Sure',
          confirmType: 'error',
          cancelType: '',
          cancelText: 'Cancel',
          onConfirm: () => this.onReceive()
        })
      } else if (type === 'cancel') {
        this.setModal({
          value: true,
          title: 'Cancel',
          message: 'Do you want to cancel this purchase order',
          confirmText: 'Sure',
          confirmType: 'error',
          cancelType: '',
          cancelText: 'Cancel',
          onConfirm: () => this.onCancel()
        })
      } else if (type === 'delivery') {
        this.setModal({
          value: true,
          title: 'Create delivery note',
          message: 'Do you want to create delivery note from this purchase order',
          confirmText: 'Sure',
          confirmType: 'error',
          cancelType: '',
          cancelText: 'Cancel',
          onConfirm: () => this.onCreateDelivery()
        })
      } else if (type === 'pending') {
        this.setModal({
          value: true,
          title: 'Reset state to "Pending"',
          message: 'Do you want to reset state this purchase order?',
          confirmText: 'Sure',
          confirmType: 'error',
          cancelType: '',
          cancelText: 'Cancel',
          onConfirm: () => this.onResetState()
        })
      }
    },
    showDeliveryNote (noteId) {
      const warehouse = this.warehouses.find((w) => w.code === this.formData.locationCode)
      const routeData = this.$router.resolve({
        name: 'DeliveryNote',
        query: {
          id: noteId,
          warehouse: warehouse.id
        }
      })
      window.open(routeData.href, '_blank')
    },
    async onApprove () {
      const validated = await this.$refs['order-form'].validate()

      if (!validated) {
        return
      }

      try {
        this.loading = true
        this.setLoading({ active: true })
        this.formData.noOfLines = this.formData.purchaseOrderLines.length
        this.formData.purchaseOrderLines = this.formData.purchaseOrderLines.map((item, index) => ({
          ...item,
          sequence: (index + 1) * 10000,
          locationCode: this.formData.locationCode,
          ifStatus: (index + 1) === this.formData.purchaseOrderLines.length ? 'COMPLETE' : ''
        }))

        await PurchaseOrdersService.updateItem(this.formData)
        await PurchaseOrdersService.approveItem(this.formData.id)
        this.setSnackbar({
          value: true,
          message: 'แก้ไขใบสั่งซื้อสำเร็จ',
          type: 'success'
        })

        this.getItem()
      } catch (error) {
        console.error('onApprove', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    async onReceive () {
      const validated = await this.$refs['order-form'].validate()

      if (!validated) {
        return
      }

      try {
        this.loading = true
        this.setLoading({ active: true })

        await PurchaseOrdersService.receiveItem(this.formData.id)
        this.setSnackbar({
          value: true,
          message: 'แก้ไขใบสั่งซื้อสำเร็จ',
          type: 'success'
        })

        this.getItem()
      } catch (error) {
        console.error('onReceive', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    async onCancel () {
      const validated = await this.$refs['order-form'].validate()

      if (!validated) {
        return
      }

      try {
        this.loading = true
        this.setLoading({ active: true })

        await PurchaseOrdersService.cancelItem(this.formData.id)
        this.setSnackbar({
          value: true,
          message: 'ยกเลิกใบสั่งซื้อสำเร็จ',
          type: 'success'
        })

        if (this.formData.type === 'FG') {
          this.$router.push({ name: 'PurchasesFinishedGoodsOrders' })
        } else {
          this.$router.push({ name: 'PurchasesOrders' })
        }
      } catch (error) {
        console.error('onCancel', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    async onResetState () {
      const validated = await this.$refs['order-form'].validate()

      if (!validated) {
        return
      }

      try {
        this.loading = true
        this.setLoading({ active: true })

        await PurchaseOrdersService.updateItem({
          ...this.formData,
          state: 'pending'
        })

        this.setSnackbar({
          value: true,
          message: 'แก้ไขใบสั่งซื้อสำเร็จ',
          type: 'success'
        })

        this.getItem()
      } catch (error) {
        console.error('onResetState', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    onCreateDelivery () {
      const warehouse = this.warehouses.find((w) => w.code === this.formData.locationCode)
      const routeData = this.$router.resolve({
        name: 'DeliveryNote',
          query: {
          poNo: this.formData.orderNo,
          warehouse: warehouse.id
        }
      })
      window.open(routeData.href, '_blank')
    },
    async onSubmit () {
      const validated = await this.$refs['order-form'].validate()

      if (!validated) {
        return
      }

      try {
        this.loading = true
        this.setLoading({ active: true })

        this.formData.noOfLines = this.formData.purchaseOrderLines.length
        this.formData.purchaseOrderLines = this.formData.purchaseOrderLines.map((item, index) => ({
          ...item,
          sequence: (index + 1) * 10000,
          locationCode: this.formData.locationCode,
          ifStatus: (index + 1) === this.formData.purchaseOrderLines.length ? 'COMPLETE' : ''
        }))

        if (this.isEdit && (this.formData.state === 'approved' || this.formData.state === 'received')) {
          const expectedReceiptDateLines = this.formData.purchaseOrderLines
            .filter((v) => {
              const poLine = this.memoFormData.purchaseOrderLines.find((p) => p.sequence === v.sequence)

              return v.expectedReceiptDate !== poLine.expectedReceiptDate
            })
            .map((v) => ({
              poLineNo: v.sequence,
              expectedReceiptDate: v.expectedReceiptDate
            }))

          if (expectedReceiptDateLines.length) {
            await PurchaseOrdersService.updateExpectedReceiptDates({
              id: this.formData.id,
              expectedReceiptDateLines
            })

            this.setSnackbar({
              value: true,
              message: 'แก้ไขใบสั่งซื้อสำเร็จ',
              type: 'success'
            })

            this.getItem()
          } else {
            this.$router.push({ name: 'PurchasesFinishedGoodsOrders' })
          }
        } else if (this.isEdit) {
          await PurchaseOrdersService.updateItem(this.formData)
          this.setSnackbar({
            value: true,
            message: 'แก้ไขใบสั่งซื้อสำเร็จ',
            type: 'success'
          })

          this.getItem()
        } else {
          const { data } = await PurchaseOrdersService.createItem(this.formData)

          this.setSnackbar({
            value: true,
            message: 'สร้างใบสั่งซื้อสำเร็จ',
            type: 'success'
          })

          this.$router.replace(`/purchases/orders/${data.id}?type=${this.type}`)

          setTimeout(() => window.location.reload(), 500)
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.loading = false
      }
    },
    selectVendor (vendor) {
      this.formData.vendor = vendor.id
      this.formData.currencyCode = vendor?.currencyCode || this.formData.currencyCode
      this.selectedVendor = { ...vendor }
      this.currencyCode = vendor.currencyCode
      this.toggleVendor()
    },
    async onSelectItem (item) {
      if (this.type === 'FG') {
        const codes = item.code.split('.')
        const model = `${codes[0]}.${codes[4]}.${codes[5]}`
        let { price } = item
        let quantity = null

        try {
          this.loading = true
          const { data: prototype } = await PrototypeService.getPrototypeByProductId(`${codes[0]}.${codes[1]}.${codes[2]}.${codes[3]}.${codes[4]}`)

          price = prototype?.order?.manufacCost ?? item.price

          const sku = (prototype?.order?.productSkus || []).find((s) => s.color === item.color.name && s.size === item.size.name)

          quantity = sku?.quantity || null
        } catch (error) {
          console.error('onSelectItem', error)
        } finally {
          this.loading = false
        }

        this.formData.purchaseOrderLines.push({
          sequence: (this.formData.purchaseOrderLines.length + 1) * 10000,
          lineType: 'Item',
          itemType: 'FG',
          itemNo: item.code,
          imageURL: item.imageURL,
          description: item.name,
          description2: item.name.slice(0, 50),
          uom: 'PCS',
          directUnitCost: price,
          groupCategory: item.groupCategories[0],
          size: item.size.name,
          color: item.color.name,
          categories: item.categories[0],
          model,
          gwCollection: item.gwCollection,
          brand: item.brand,
          useForProducts: [],
          expectedReceiptDate: null,
          locationCode: '',
          quantity,
          discountAmount: 0,
          discountPercent: 0,
          ifStatus: '',
          search: '',
          searching: false,
          searchTimeout: null
        })
      } else {
        this.formData.purchaseOrderLines.push({
          sequence: (this.formData.purchaseOrderLines.length + 1) * 10000,
          lineType: 'Item',
          itemType: 'RM',
          itemNo: item.itemNo,
          imageURL: item.imageURL,
          description: item.description,
          description2: item.description2.slice(0, 50),
          uom: item.baseUOM || item.salesUOM || item.purchaseUOM || '',
          directUnitCost: item.unitPrice,
          groupCategory: item.groupCategory,
          size: item.size,
          color: item.color,
          categories: item.categories,
          model: 'NONE',
          gwCollection: item.gwCollection,
          brand: item.brand,
          useForProducts: [],
          expectedReceiptDate: null,
          locationCode: '',
          quantity: null,
          discountAmount: 0,
          discountPercent: 0,
          ifStatus: '',
          search: '',
          searching: false,
          searchTimeout: null
        })
      }
    },
    onRemoveItem (item) {
      const index = this.formData.purchaseOrderLines.findIndex((v) => v.itemNo === item.itemNo)

      if (index !== -1) {
        this.formData.purchaseOrderLines.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped>
.disabled-text {
  color: #9b9b9b;
}
.container-bg {
  background-color: white;
}
.header-line {
  position: relative;
}
.header-line span {
  font-weight: 500;
  background-color: white;
  padding-right: 8px;
  position: absolute;
  left: 12px;
  top: 0;
}
.main-image {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 215px;
  object-position: center;
  object-fit: contain;
}
</style>

<style>
.switch-container .v-input--selection-controls {
  margin-top: 5px !important;
}
</style>
